/**
 * Typography util classes
 */
@use 'sass:map';
@use 'sass:string';

@import '../config';
@import '../functions';
@import '../mixins';

$typography-alignments: ('left', 'right', 'center', 'justify');
$typography-vertical-alignments: ('baseline', 'middle', 'top', 'bottom', 'sub', 'super');
$typography-word-break-styles: ('normal', 'break-word', 'initial', 'inherit', 'break-all', 'keep-all');
$typography-text-decoration: ('line-through', 'underline', 'overline');

:global {
    .ace-u-typography {
        @include typography($typography-styles, 'body');
    }
}

@each $key, $value in $typography-styles {
    :global(.ace-u-typography--variant-#{$key}) {
        text-decoration: none;

        @include typography($typography-styles, #{$key});
    }
}

@each $key, $value in $base-colors {
    @if (string.index($key, 'text-')) {
        :global(.ace-u-typography--color-#{string.slice($key, 6)}) {
            color: $value;
        }
    }
}

@each $alignment in $typography-alignments {
    :global(.ace-u-typography--align-#{$alignment}) {
        text-align: #{$alignment};
    }
}

@each $alignment in $typography-vertical-alignments {
    :global(.ace-u-typography--vertical-align-#{$alignment}) {
        vertical-align: #{$alignment};
    }
}

@each $word-break-style in $typography-word-break-styles {
    :global(.ace-u-typography-word-break--#{$word-break-style}) {
        word-break: #{$word-break-style};
    }
}

@each $text-decoration in $typography-text-decoration {
    :global(.ace-u-typography--text-decoration-#{$text-decoration}) {
        text-decoration: #{$text-decoration};
    }
}

@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './badge-config';

.ace-c-badge {
    @include typography($typography-styles, map-get($badge-config, badge-typography));

    color: map-get($badge-config, badge-text-color);
    padding: map-get($badge-config, badge-padding);
    border-radius: map-get($badge-config, badge-border-radius);
    background: map-get($badge-config, badge-background);

    &--status-default {
        background: map-get($badge-config, badge-background);
    }

    &--status-notification {
        background: map-get($badge-config, badge-background-notification);
    }

    &--status-silver {
        background: map-get($badge-config, badge-background-silver);
    }

    &--status-gold {
        background: map-get($badge-config, badge-background-gold);
    }

    &--status-platinum {
        background: map-get($badge-config, badge-background-platinum);
    }

    &--status-positive {
        background: map-get($badge-config, badge-background-positive);
    }

    &--status-negative {
        background: map-get($badge-config, badge-background-negative);
    }

    &--status-information {
        background: map-get($badge-config, badge-background-information);
    }
}

@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './option-config';

@include state-styles.configure('ace-c-option', $option-config);

.ace-c-option {
    @include typography($typography-styles, map-get($option-config, option-typography));

    position: relative;
    min-width: 280px;
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: map-get($option-config, option-padding);
    color: map-get($option-config, option-text-color);
    background: map-get($option-config, option-background);
    border-bottom: map-get($option-config, option-border);
    cursor: pointer;
    user-select: none;

    &--small {
        height: 40px;
        padding:
            map-get($option-config, option-padding-small)
            map-get($option-config, option-padding);
    }

    &--is-disabled {
        cursor: default;
    }

    @include state-styles.block() using($get-design-token) {
        @include typography($typography-styles, meta.call($get-design-token, option-typography));

        color: meta.call($get-design-token, option-text-color);
        background: meta.call($get-design-token, option-background);
        border-bottom: meta.call($get-design-token, option-border);
    }
}

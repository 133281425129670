@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';

.ace-c-complex-field {
    display: inline-block;
    padding: map-get($sizes, ace-4);
    border: 1px solid map-get($color-palette, ace-red-800);

    &--is-disabled {
        border: 1px solid map-get($color-palette, ace-gray-800);
    }
}

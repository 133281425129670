@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './screen-title-config';

.ace-c-screen-title {
    @include typography($typography-styles, map-get($screen-title-config, screen-title-typography));

    height: map-get($screen-title-config, screen-title-height);
    padding: 0 map-get($screen-title-config, content-padding);
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: map-get($screen-title-config, screen-title-background);
    color: map-get($screen-title-config, screen-title-text-color);

    @include media('>=large') {
        padding: 0 map-get($screen-title-config, content-padding-large);
    }
}

@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './data-row-config';

.ace-c-data-row {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid map-get($data-row-config, data-row-border-color);
}

.ace-c-data-row__label {
    @include typography($typography-styles, map-get($data-row-config, data-row-label-typography));

    min-width: 248px;
    width: 248px;
    padding: map-get($data-row-config, data-row-label-padding);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    background-color: map-get($data-row-config, data-row-label-background);

    &--is-field-required {
        flex-flow: nowrap;
    }
}

.ace-c-data-row__content {
    @include typography($typography-styles, map-get($data-row-config, data-row-content-typography));

    flex-grow: 1;
    display: flex;
    min-width: 0;
    align-items: center;
    padding: map-get($data-row-config, data-row-content-padding);
    overflow: hidden;

    &--no-wrap {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .ace-c-data-row--condensed & {
        padding: map-get($data-row-config, data-row-content-padding-condensed);
    }

    .ace-c-data-row--full-bleed-content & {
        margin: map-get($data-row-config, data-row-content-padding) * -1;
    }

    .ace-c-data-row--full-bleed-content.ace-c-data-row--condensed & {
        margin: map-get($data-row-config, data-row-content-padding-condensed-full-bleed);
    }
}

.ace-c-data-row__span {
    color: map-get($data-row-config, data-row-asterics-color);
    margin-left: map-get($data-row-config, data-row-asterics-margin);
}

@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import './normalize';
@import './reset';
@import './box-sizing';
@import './config';

/**
 * Global application styling
 */

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

// Basics
:global {
    html,
    body,
    #root {
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 148%;
        letter-spacing: 0.01em;
        overflow-x: hidden; // TODO check if needed?
        // do not apply font-smoothing rules
        -webkit-font-smoothing: unset !important;
        -moz-osx-font-smoothing: unset !important;
    }

    body {
        position: relative;
    }
}

// fixes inconsistent API issue in `svg-sprite-loader`
// see: https://github.com/kisenka/svg-sprite-loader/issues/123
// stylelint-disable-next-line no-duplicate-selectors
:global {
    .sprite-symbol-usage {
        display: block !important;
    }
}

// Import util classes
@import './util-classes/typography.module';
@import './util-classes/box-model.module';
@import './util-classes/flexbox.module';
@import './util-classes/grid.module';
@import './util-classes/pointer.module';
@import './util-classes/box-skin.module';
@import './util-classes/modal-content-size.module';

// Project specific
@import './project-specific.module';

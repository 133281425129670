@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './highlight-circle-config';

$background-variants: (
    negative,
    success,
    primary,
    primary-highlight,
    default
);

.ace-c-highlight-circle {
    @include typography($typography-styles, map-get($highlight-circle-config, highlight-circle-typography));

    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    background-color: map-get($highlight-circle-config, highlight-circle-backgorund-default);
    height: map-get($highlight-circle-config, highlight-circle-size-regular);
    width: map-get($highlight-circle-config, highlight-circle-size-regular);

    &--large {
        width: map-get($highlight-circle-config, highlight-circle-size-large);
        height: map-get($highlight-circle-config, highlight-circle-size-large);
    }

    &--medium {
        width: map-get($highlight-circle-config, highlight-circle-size-medium);
        height: map-get($highlight-circle-config, highlight-circle-size-medium);
    }

    &--small {
        width: map-get($highlight-circle-config, highlight-circle-size-small);
        height: map-get($highlight-circle-config, highlight-circle-size-small);
    }

    &--warning {
        background-color: map-get($highlight-circle-config, highlight-circle-backgorund-warning);
    }

    @each $background-variant in $background-variants {
        &--#{$background-variant} {
            background-color: map-get($highlight-circle-config, highlight-circle-background-#{$background-variant});
        }
    }
}

@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './form-field-config';

.ace-c-form-field {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

.ace-c-form-field__input-label {
    @include typography($typography-styles, map-get($form-field-config, form-field-label-typography));

    display: block;
    height: map-get($form-field-config, form-field-label-height);
    margin-bottom: map-get($form-field-config, form-field-margin);
    color: map-get($form-field-config, text-color-default);

    .ace-c-form-field--is-disabled & {
        color: map-get($form-field-config, form-field-text-disabled-color);
    }

    .ace-c-form-field--has-error & {
        color: map-get($form-field-config, form-field-text-error-color);
    }
}

.ace-c-form-field__error-message {
    @include typography($typography-styles, map-get($form-field-config, form-field-label-typography));

    color: map-get($form-field-config, form-field-text-error-color);
    display: flex;
    align-items: center;
    margin-top: map-get($form-field-config, form-field-margin);
}

.ace-c-form-field__warning-sign {
    width: 14px;
    height: 14px;
    display: inline-flex;
    padding-right: map-get($form-field-config, form-field-padding);
    background-image: url('../assets/icons/warning-sign.svg?fill=#{map-get($form-field-config, form-field-text-error-color)}');
    background-repeat: no-repeat;

    &--disabled {
        display: none;
    }
}

.ace-c-form-field__span {
    color: map-get($form-field-config, form-field-asterics-color);
    margin-left: map-get($form-field-config, form-field-asterics-margin);
}

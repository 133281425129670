@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './number-input-config';

.ace-c-number-input {
    @include typography($typography-styles, map-get($number-input-config, input-text-typography));

    display: inline;
    height: map-get($number-input-config, input-height);
    padding: 0 map-get($sizes, ace-16);
    border: 1px solid map-get($number-input-config, input-border-color);
    caret-color: map-get($number-input-config, input-caret-color);
    background: map-get($number-input-config, input-background-color);

    &--small {
        height: 40px;
    }

    &::-webkit-input-placeholder {
        color: map-get($number-input-config, input-placeholder-text);
    }

    &:focus {
        outline: none;
    }

    &--is-disabled {
        background: map-get($number-input-config, input-background-color-disabled);
        border: 1px solid map-get($number-input-config, input-border-color-disabled);
        color: map-get($number-input-config, input-text-disabled);
    }

    &--has-error {
        border: 1px solid map-get($number-input-config, input-border-color-error);
        color: map-get($number-input-config, input-text-error);
    }

    &--is-required:not(#{&}--is-disabled) {
        border: 1px solid map-get($number-input-config, input-border-color-empty-mandatory);
    }
}

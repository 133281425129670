@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './interactive-icon-config';

@include state-styles.configure('ace-c-interactive-icon', $interactive-icon-config);

.ace-c-interactive-icon {
    @include typography($typography-styles, map-get($interactive-icon-config, interactive-icon-typography));

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    cursor: pointer;
    user-select: none;
    pointer-events: auto;
    color: map-get($interactive-icon-config, interactive-icon-color);

    &--highlight {
        @include typography(
            $typography-styles,
            map-get($interactive-icon-config, interactive-icon-typography-highlight)
        );
    }

    &--reverse {
        flex-direction: row-reverse;
    }

    &--primary {
        color: map-get($interactive-icon-config, interactive-icon-color-primary);

        svg {
            fill: map-get($interactive-icon-config, interactive-icon-color-primary);
        }
    }

    &--contrast {
        color: map-get($interactive-icon-config, interactive-icon-color-contrast);

        svg {
            fill: map-get($interactive-icon-config, interactive-icon-color-contrast);
        }
    }

    &--is-disabled {
        cursor: auto !important;
    }

    @include state-styles.block(
        $for-states: (disabled, hover, active),
    ) using($get-design-token) {
        color: meta.call($get-design-token, interactive-icon-color);
    }
}

.ace-c-interactive-icon__icon {
    margin: 0;
    width: map-get($interactive-icon-config, interactive-icon-size-m);
    height: map-get($interactive-icon-config, interactive-icon-size-m);

    .ace-c-interactive-icon--has-label & {
        margin-right: map-get($interactive-icon-config, interactive-icon-label-spacing);
    }

    .ace-c-interactive-icon--s & {
        width: map-get($interactive-icon-config, interactive-icon-size-s);
        height: map-get($interactive-icon-config, interactive-icon-size-s);
    }

    .ace-c-interactive-icon--m & {
        width: map-get($interactive-icon-config, interactive-icon-size-m);
        height: map-get($interactive-icon-config, interactive-icon-size-m);
    }

    .ace-c-interactive-icon--l & {
        width: map-get($interactive-icon-config, interactive-icon-size-l);
        height: map-get($interactive-icon-config, interactive-icon-size-l);
    }

    .ace-c-interactive-icon--xl & {
        width: map-get($interactive-icon-config, interactive-icon-size-xl);
        height: map-get($interactive-icon-config, interactive-icon-size-xl);
    }

    .ace-c-interactive-icon--xxl & {
        width: map-get($interactive-icon-config, interactive-icon-size-xxl);
        height: map-get($interactive-icon-config, interactive-icon-size-xxl);
    }

    > svg {
        @include state-styles.element(
            $for-states: (disabled, hover, active),
        ) using($get-design-token) {
            fill: meta.call($get-design-token, interactive-icon-color);
        }
    }

    .ace-c-interactive-icon--has-label.ace-c-interactive-icon--reverse & {
        margin-left: map-get($interactive-icon-config, interactive-icon-label-spacing);
    }
}

.ace-c-interactive-icon:focus {
    color: map-get($interactive-icon-config, interactive-icon-color-focus);
    outline: none;

    svg {
        fill: map-get($interactive-icon-config, interactive-icon-color-focus);
    }
}

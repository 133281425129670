/* stylelint-disable scss/dollar-variable-colon-newline-after */
/* stylelint-disable function-parentheses-newline-inside, function-comma-newline-after */

// Responsive
// quick start: https://include-media.com/
// documentation: https://include-media.com/documentation
@import '~include-media/dist/include-media';
@import './functions';

$breakpoints: () !default;

$breakpoints: map-extend((
    base: 320px,
    small: 960px,
    large: 1280px,
    x-large: 1920px,
), $breakpoints);

$media-expressions: () !default;

$media-expressions: map-extend((
    screen: 'screen',
    print: 'print',
    handheld: 'handheld',
    landscape: '(orientation: landscape)',
    portrait: '(orientation: portrait)',
    retina2x: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
    retina3x: '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi)',
    touch-device: '(pointer: coarse), (hover: none)',
), $media-expressions);

$sizes: () !default;

$sizes: map-extend((
    ace-0: 0,
    ace-4: 4px,
    ace-8: 8px,
    ace-16: 16px,
    ace-24: 24px,
    ace-32: 32px,
    ace-40: 40px,
    ace-48: 48px,
    ace-64: 64px,
    ace-128: 128px,
    ace-192: 192px,
    ace-256: 256px,
    ace-750: 750px,
), $sizes);

$grid: () !default;

$grid: map-extend((
    grid-columns: 12,
    grid-column-gap: 24px,
    grid-row-gap: 24px,
), $grid);

$modal-sizes: () !default;

$modal-sizes: map-extend((
    modal-size-2xs: 300px,
    modal-size-xs: 600px,
    modal-size-s: 750px,
    modal-size-m: 1280px,
    modal-size-l: 1600px,
    modal-size-xl: 1900px,
), $modal-sizes);

$z-index: () !default;

$z-index: map-extend((
    dashboard: 1,
    lock: 50,
    navigation: 100,
    modal: 800,
    drop-down: 1000,
    tooltip: 1100,
), $z-index);

$color-palette: () !default;

$color-palette: map-extend((
    ace-red-900: #b93422,
    ace-red-800: #c83e2e,
    ace-red-700: #d54435,
    ace-red-600: #e74d3b,
    ace-red-500: #f5553b,
    ace-red-400: #f16255,
    ace-red-300: #e77d78,
    ace-red-200: #f0a19d,
    ace-red-100: #ffd1d4,
    ace-red-50: #ffedef,
    ace-grey-900: #181616,
    ace-grey-800: #383636,
    ace-grey-700: #565454,
    ace-grey-600: #696767,
    ace-grey-500: #918f8f,
    ace-grey-400: #bebebf,
    ace-grey-300: #e1e1e2,
    ace-grey-200: #efeff0,
    ace-grey-100: #f5f5f6,
    ace-grey-50: #fafafb,
    ace-yellow-900: #d24e0f,
    ace-yellow-800: #db6714,
    ace-yellow-700: #e27618,
    ace-yellow-600: #e8851b,
    ace-yellow-500: #ec901d,
    ace-yellow-400: #eea035,
    ace-yellow-300: #f1b055,
    ace-yellow-200: #f5c784,
    ace-yellow-100: #f9ddb4,
    ace-yellow-50: #fcf2e1,
    ace-blue-900: #3b469c,
    ace-blue-800: #4565bc,
    ace-blue-700: #4a76cf,
    ace-blue-600: #5188e2,
    ace-blue-500: #5596f1,
    ace-blue-400: #63a5f4,
    ace-blue-300: #79b5f6,
    ace-blue-200: #9ccafa,
    ace-blue-100: #c1defc,
    ace-blue-50: #e5f2fd,
    ace-green-900: #006100,
    ace-green-800: #007f00,
    ace-green-700: #1b9000,
    ace-green-600: #2da200,
    ace-green-500: #38b100,
    ace-green-400: #5abd3d,
    ace-green-300: #7ac963,
    ace-green-200: #99d897,
    ace-green-100: #c7e7bc,
    ace-green-50: #e7f5e4,
    ace-green-10: #d3e0e1,
    ace-lime-900: #94860a,
    ace-lime-800: #afad19,
    ace-lime-700: #c0c522,
    ace-lime-600: #d2db2c,
    ace-lime-500: #deed31,
    ace-lime-400: #e4f155,
    ace-lime-300: #eaf576,
    ace-lime-200: #f0f89d,
    ace-lime-100: #f6fac4,
    ace-lime-50: #fcfde8,
    ace-dark-blue-100: #1e369e,
    ace-dark-blue-15: #dde1f1,
    ace-dark-blue-5: #f4f5fa,
    ace-black: #000,
    ace-white: #fff,
    ace-red: #f00,
    ace-green: #5db653,
), $color-palette);

$base-colors: () !default;

$base-colors: map-extend((
    primary: map-get($color-palette, ace-yellow-300),
    primary-dark-a: map-get($color-palette, ace-yellow-400),
    primary-dark-b: map-get($color-palette, ace-yellow-500),
    primary-light-a: map-get($color-palette, ace-yellow-200),
    primary-light-b: map-get($color-palette, ace-yellow-100),
    primary-light-c: map-get($color-palette, ace-yellow-50),
    primary-highlight: rgba(map-get($color-palette, ace-yellow-300), 0.1),
    secondary: map-get($color-palette, ace-grey-700),
    secondary-dark: map-get($color-palette, ace-grey-800),
    secondary-light-a: map-get($color-palette, ace-grey-600),
    secondary-light-b: map-get($color-palette, ace-grey-500),
    secondary-light-c: map-get($color-palette, ace-grey-400),
    secondary-light-d: map-get($color-palette, ace-grey-300),
    warning: map-get($color-palette, ace-red-500),
    negative: map-get($color-palette, ace-red-500),
    negative-light: map-get($color-palette, ace-red-50),
    negative-dark: map-get($color-palette, ace-red-700),
    positive: map-get($color-palette, ace-green-500),
    positive-dark: map-get($color-palette, ace-green-800),
    positive-light: map-get($color-palette, ace-green-50),
    contrast: map-get($color-palette, ace-white),
    disabled-main: map-get($color-palette, ace-grey-400),
    disabled-dark: map-get($color-palette, ace-grey-500),
    disabled-light: map-get($color-palette, ace-grey-200),
    background-main: map-get($color-palette, ace-white),
    background-white: map-get($color-palette, ace-white),
    background-transparent: transparent,
    background-b: map-get($color-palette, ace-grey-50),
    background-c: map-get($color-palette, ace-grey-100),
    background-modal: rgba(map-get($color-palette, ace-white), 0.2),
    background-modal-backdrop: rgba(map-get($color-palette, ace-black), 0.2),
    divider: map-get($color-palette, ace-grey-300),
    aditional-a: map-get($color-palette, ace-blue-300),
    aditional-b: map-get($color-palette, ace-blue-600),
    aditional-c: map-get($color-palette, ace-green-200),
    aditional-d: map-get($color-palette, ace-red-200),
    aditional-e: map-get($color-palette, ace-red-300),
    aditional-f: map-get($color-palette, ace-blue-900),
    aditional-g: map-get($color-palette, ace-red-100),
    additional-h: map-get($color-palette, ace-blue-50),
    additional-i: map-get($color-palette, ace-green-10),
    // text colors
    text-default: map-get($color-palette, ace-grey-700),
    text-disabled: map-get($color-palette, ace-grey-500),
    text-disabled-main: map-get($color-palette, ace-grey-400),
    text-contrast: map-get($color-palette, ace-white),
    text-warning: map-get($color-palette, ace-red-500),
    text-warning-light: map-get($color-palette, ace-red-50),
    text-success: map-get($color-palette, ace-green-800),
    text-highlighted: map-get($color-palette, ace-yellow-400),
    text-higlighted-hover: map-get($color-palette, ace-yellow-200),
    text-higlighted-active: map-get($color-palette, ace-yellow-500),
    text-medium-emphasis: map-get($color-palette, ace-grey-500),
    text-website-link: map-get($color-palette, ace-blue-600),
    // icon colors
    icon-default: map-get($color-palette, ace-grey-500),
    icon-medium-emphasis: map-get($color-palette, ace-grey-400),
    icon-disabled: map-get($color-palette, ace-grey-400),
    icon-disabled-light: map-get($color-palette, ace-grey-200),
    icon-success: map-get($color-palette, ace-green-500),
    icon-warning: map-get($color-palette, ace-red-500),
    icon-warning-light: map-get($color-palette, ace-red-50),
    icon-warning-dark: map-get($color-palette, ace-red-900),
    icon-highlight: map-get($color-palette, ace-yellow-400),
    icon-highlight-light: map-get($color-palette, ace-yellow-200),
    icon-highlight-pressed: map-get($color-palette, ace-yellow-500),
    icon-highlight-hover: map-get($color-palette, ace-yellow-200),
    icon-contrast: map-get($color-palette, ace-white),
    // shadows
    shadow-1: 1px 1px 4px rgba(map-get($color-palette, ace-black), 0.25),
    shadow-2: 1px 2px 4px 2px rgba(map-get($color-palette, ace-black), 0.25),
    shadow-3: 1px 4px 7px rgba(map-get($color-palette, ace-black), 0.35),
    shadow-4: 0 0 0 4px map-get($color-palette, ace-yellow-100),
    shadow-card: 1px 1px 23px 8px rgba(map-get($color-palette, ace-black), 0.1),
    shadow-focus: 0 0 0 4px map-get($color-palette, ace-yellow-100),
    shadow-error: 0 0 0 4px map-get($color-palette, ace-red-100),
    shadow-control-active: 0 0 0 8px rgba(map-get($color-palette, ace-red-700), 0.1),
    shadow-control-active-m: 0 0 0 12px rgba(map-get($color-palette, ace-red-700), 0.1),
    shadow-modal-content: 0 0 8px 1px rgba(map-get($color-palette, ace-black), 0.15),
    shadow-scrollable-block: 0 -4px 7px -3px rgba(map-get($color-palette, ace-black), 0.15),
    shadow-linked-list-top: 0 -11px 8px 1px rgba(map-get($color-palette, ace-black), 0.8),
    shadow-linked-list-bottom: 0 11px 8px 1px rgba(map-get($color-palette, ace-black), 0.8),
    shadow-date-time-picker: 0 2px 20px rgba(map-get($color-palette, ace-black), 0.4),
), $base-colors);

$typography-styles: () !default;

$typography-styles: map-extend((
    h1: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: bold,
        font-size: 64px,
        line-height: 112%,
        font-feature-settings: 'liga' off,
        color: map-get($base-colors, text-default),
    ),
    h2: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: bold,
        font-size: 40px,
        line-height: 120%,
        color: map-get($base-colors, text-default),
    ),
    h3: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: bold,
        font-size: 24px,
        line-height: 136%,
        color: map-get($base-colors, text-default),
    ),
    subtitle: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: normal,
        font-size: 24px,
        line-height: 136%,
        color: map-get($base-colors, text-default),
    ),
    body: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: normal,
        font-size: 16px,
        line-height: 148%,
        letter-spacing: 0.01em,
        color: map-get($base-colors, text-default),
    ),
    body-medium: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: 600,
        font-size: 16px,
        line-height: 148%,
        letter-spacing: 0.01em,
        color: map-get($base-colors, text-default),
    ),
    body-bold: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: bold,
        font-size: 16px,
        line-height: 148%,
        letter-spacing: 0.04em,
        color: map-get($base-colors, text-default),
    ),
    caption: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: normal,
        font-size: 12px,
        line-height: 132%,
        color: map-get($base-colors, text-default),
    ),
    caption-bold: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: bold,
        font-size: 12px,
        line-height: 132%,
        color: map-get($base-colors, text-default),
    ),
    highlight: (
        font-family: 'Open Sans',
        font-style: normal,
        font-weight: bold,
        font-size: 14px,
        line-height: 168%,
        letter-spacing: 0.1em,
        color: map-get($base-colors, text-default),
        text-transform: uppercase,
    ),
), $typography-styles);

$typography-styles-keys: () !default;

$typography-styles-keys: map-extend((
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    subtitle: 'subtitle',
    body: 'body',
    body-medium: 'body-medium',
    body-bold: 'body-bold',
    caption: 'caption',
    caption-bold: 'caption-bold',
    highlight: 'highlight'
), $typography-styles-keys);

@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './pill-config';

.ace-c-pill {
    display: inline-flex;
    align-items: center;
    height: map-get($pill-config, pill-height);
    max-height: map-get($pill-config, pill-height);
    padding: map-get($pill-config, pill-padding);
    border-radius: map-get($pill-config, pill-border-radius);
    color: map-get($pill-config, pill-text-color);
    background: map-get($pill-config, pill-background);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--pending {
        color: map-get($pill-config, pill-text-color-pending);
        background: map-get($pill-config, pill-background-pending);
    }

    &--pending-highlighted {
        color: map-get($pill-config, pill-text-color-pending-highlighted);
        background: map-get($pill-config, pill-background-pending-highlighted);
    }

    &--pending-bold-highlighted {
        color: map-get($pill-config, pill-text-color-pending-bold-highlighted);
        background: map-get($pill-config, pill-background-pending-bold-highlighted);
        font-weight: bold;
    }

    &--positive {
        color: map-get($pill-config, pill-text-color-positive);
        background: map-get($pill-config, pill-background-positive);
    }

    &--positive-bold-highlighted {
        color: map-get($pill-config, pill-text-color-positive-bold-highlighted);
        background: map-get($pill-config, pill-background-positive-bold-highlighted);
        font-weight: bold;
    }

    &--negative {
        color: map-get($pill-config, pill-text-color-negative);
        background: map-get($pill-config, pill-background-negative);
    }

    &--negative-bold-highlighted {
        color: map-get($pill-config, pill-text-color-negative-bold-highlighted);
        background: map-get($pill-config, pill-background-negative-bold-highlighted);
        font-weight: bold;
    }

    &--information {
        color: map-get($pill-config, pill-text-color-information);
        background: map-get($pill-config, pill-background-information);
    }

    &--information-highlighted {
        color: map-get($pill-config, pill-text-color-information-highlighted);
        background: map-get($pill-config, pill-background-information-highlighted);
    }

    &--information-bold-highlighted {
        color: map-get($pill-config, pill-text-color-information-bold-highlighted);
        background: map-get($pill-config, pill-background-information-bold-highlighted);
        font-weight: bold;
    }

    &--small {
        height: map-get($pill-config, pill-height-small);
        max-height: map-get($pill-config, pill-height-small);
    }
}

.ace-c-pill__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@use 'sass:map';
@use 'sass:string';

@import '../config';
@import '../functions';
@import '../mixins';

$box-skin-config: (
    box-skin-background: map-get($base-colors, background-c),
    box-skin-background-pending: map-get($base-colors, background-c),
    box-skin-background-positive: map-get($base-colors, positive-light),
    box-skin-background-negative: map-get($base-colors, negative-light),
    box-skin-background-negative-dark: map-get($base-colors, negative-dark),
    box-skin-background-information: map-get($base-colors, primary-light-c),
    box-skin-text-color: map-get($base-colors, text-default),
    box-skin-text-color-pending: map-get($base-colors, text-default),
    box-skin-text-color-positive: map-get($base-colors, text-success),
    box-skin-text-color-negative: map-get($base-colors, text-warning),
    box-skin-text-color-negative-dark: map-get($base-colors, text-warning-light),
    box-skin-text-color-information: map-get($base-colors, text-default),
);

:global {
    .ace-u-box-skin {
        color: map-get($box-skin-config, box-skin-text-color);
        background: map-get($box-skin-config, box-skin-background);

        &--pending {
            color: map-get($box-skin-config, box-skin-text-color-pending);
            background: map-get($box-skin-config, box-skin-background-pending);
        }

        &--positive {
            color: map-get($box-skin-config, box-skin-text-color-positive);
            background: map-get($box-skin-config, box-skin-background-positive);
        }

        &--negative {
            color: map-get($box-skin-config, box-skin-text-color-negative);
            background: map-get($box-skin-config, box-skin-background-negative);
        }

        &--information {
            color: map-get($box-skin-config, box-skin-text-color-information);
            background: map-get($box-skin-config, box-skin-background-information);
        }

        &--small {
            height: map-get($box-skin-config, box-skin-height-small);
            max-height: map-get($box-skin-config, box-skin-height-small);
        }

        &--negative-dark {
            color: map-get($box-skin-config, box-skin-text-color-negative-dark);
            background: map-get($box-skin-config, box-skin-background-negative-dark);
        }
    }
}

@each $key, $value in $base-colors {
    :global(.ace-u-box-skin--color-#{$key}) {
        background-color: $value;
    }
}

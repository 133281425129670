@import '/home/jenkins/agent/workspace/PREPROD/ac-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './input-config';

.ace-c-input-phone-number {
    @include typography($typography-styles, map-get($input-config, input-text-typography));

    display: inline;
    height: map-get($input-config, input-height);
    padding: 0 map-get($sizes, ace-16);
    border: 1px solid map-get($input-config, input-border-color);
    caret-color: map-get($input-config, input-caret-color);
    background: map-get($input-config, input-background-color);

    &--small {
        height: map-get($input-config, input-height-small);
    }

    &::-webkit-input-placeholder {
        color: map-get($input-config, input-placeholder-text);
    }

    /* remove number input spinners Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:focus {
        outline: none;
        border: 1px solid map-get($input-config, input-border-focus);
        box-shadow: map-get($input-config, input-box-shadow-focus);
    }

    &--is-disabled {
        background: map-get($input-config, input-background-color-disabled);
        border: 1px solid map-get($input-config, input-border-color-disabled);
        color: map-get($input-config, input-text-disabled);
    }

    &--has-error {
        border: 1px solid map-get($input-config, input-border-color-error);
        color: map-get($input-config, input-text-error);
        box-shadow: map-get($input-config, input-box-shadow-error);
    }

    &--is-required:not(#{&}--is-disabled) {
        border: 1px solid map-get($input-config, input-border-color-empty-mandatory);
    }

    /* remove number input spinners Firefox */
    -moz-appearance: textfield;
}
